import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class changelog extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.changelog" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx sm="12">

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.3.0 – 07.11.2019</CardTitle>
                <p className="mb-0">Removed Owl Carousel in favor of Glide.</p>
                <p className="mb-0">Small layout fixes for popovers and tooltips.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.2.5 – 02.11.2019</CardTitle>
                <p className="mb-0">Mobile layout fixes for chat application.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.2.4 – 01.11.2019</CardTitle>
                <p className="mb-0">Layout fixes for chat application.</p>
                <p className="mb-0">Postion fixes for date picker and tooltips.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.2.3 – 14.10.2019</CardTitle>
                <p className="mb-0">Added a footer.</p>
                <p className="mb-0">Layout fixes for scrollbars.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.2.2 – 06.09.2019</CardTitle>
                <p className="mb-0">Improvements on Datatables.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.2.1 – 25.08.2019</CardTitle>
                <p className="mb-0">Updated to Bootstrap 4.3</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.1.2 – 12.08.2019</CardTitle>
                <p className="mb-0">Fixed some small problems with menu.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.1.0 – 30.07.2019</CardTitle>
                <p className="mb-0">Added rounded interface.</p>
                <p className="mb-0">Added following pages<br />
                  - Details page<br />
                  - Profile pages<br />
                  - Blog list and detail pages<br />
                  - Coming soon<br />
                  - Knowledge base<br />
                  - Pricing</p>
                <p className="mb-0">Implemented following plugins<br />
                  - Wizard<br />
                  - Validation
              </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.0.4 – 27.06.2019</CardTitle>
                <p className="mb-0">Fixed a problem with Chartjs canvas.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.0.3 – 23.06.2019</CardTitle>
                <p className="mb-0">Fixed some responsive problems about rtl.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.0.2 – 23.06.2019</CardTitle>
                <p className="mb-0">Fixed some layout problems.</p>
              </CardBody>
            </Card>


            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.0.1 – 23.06.2019</CardTitle>
                <p className="mb-0">Added rtl support.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 2.0.0 – 13.02.2019</CardTitle>
                <p className="mb-0">Removed landing pages.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 1.2.0 – 10.02.2019</CardTitle>
                <p className="mb-0">Added mailing page.</p>
                <p className="mb-0">Added invoce page.</p>
                <p className="mb-0">Added tables page.</p>
                <p className="mb-0">Removed background images in the favor of svg via css.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 1.1.1 – 04.02.2019</CardTitle>
                <p className="mb-0">Updated our api urls.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 1.1.0 – 23.10.2018</CardTitle>
                <p className="mb-0">Library Application</p>
                <p className="mb-0">Enhancements on list pages</p>
                <p className="mb-0">Keyboard shortcuts</p>
                <p className="mb-0">Right click menu</p>
                <p className="mb-0">Added spinner to all html pages</p>
                <p className="mb-0">Changed view icons on list pages</p>
                <p className="mb-0">Small bug fixes</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 1.0.1 – 29.09.2018</CardTitle>
                <p className="mb-0">We experienced a copyright issue with one of the icon libraries. Due to large usage of the icons through the project, source is completely altered. We are sorry that will not be able to provide a guide to merging new version since nearly all the files have changed.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Version 1.0.0 – 20.09.2018</CardTitle>
                <p className="mb-0">Initial release version.</p>
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
