import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class changeStartingTheme extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-change-starting-theme" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                It is defined in "js/scripts.js" file via below variable and you may change it to any file name at "css" folder directory.
                </p>


                <pre className="mb-0">
                  {
                    `var theme = "dore.light.blue.min.css";`}
                </pre>




              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
