import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class development extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.development" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Quick Start</CardTitle>
                <p className="mb-0">This theme was created to work on your computer file system without the need for a server. Extract the zip file and you are good to go.</p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <CardTitle>Gulp & Package.json</CardTitle>
                <p>
                  This template ships with a Gulp file and a package.json file
                  that contains Gulp plugins. If you never heard of Gulp before, it is an automation tool
                  that takes lots work from developers shoulder. It compiles
                  sass, minifies javascript, css and html files, easily creates
                  build versions and so much more.
                </p>
                <p>
                  To make Gulp work for the project, here are the steps.
                </p>
                <br />
                <CardSubtitle>1. Installing Node</CardSubtitle>
                <p>Gulp requires a package manager to be installed. You may use <code>npm</code> or <code>yarn</code> to serve this purpose. A side note about Node version: Please use <b>LTS version(not Current)</b> since some of the external plugins does not work on it yet.</p>
                <p className="mb-0">
                  <b>Npm Installation:</b> <a rel="noopener noreferrer" className="btn-link" target="_blank" href="https://nodejs.org/en/download/">https://nodejs.org/en/download/</a>
                  <br />
                  <b>Yarn Installation:</b> <a rel="noopener noreferrer" className="btn-link" target="_blank" href="https://yarnpkg.com/lang/en/docs/install/">https://yarnpkg.com/lang/en/docs/install/</a>
                </p>

                <br />
                <br />
                <CardSubtitle>2. Installing Packages</CardSubtitle>
                <p className="mb-0">
                  Navigate to project directory where package.json file is
                  located with command line tool and use npm install command
                  to get plugins. When this step completed, a folder with a
                  name node_modules should appear.
                </p>

                <br />
                <br />
                <CardSubtitle>3. Running Gulp</CardSubtitle>
                <p>
                  That is it! There are two major functions. When you type
                  "guld" at command line, it invokes default function which
                  compiles sass, open the browser with a start page and
                  watches for the file changes. There is another function
                  that minifies everything and makes project ready for
                  distribution. It may be called with "gulp build" command.
                </p>
                <p className="mb-0">
                  If you want to learn more about Gulp, here is a good starting point.{" "}
                  <a
                    className="btn-link"
                    href="https://css-tricks.com/gulp-for-beginners/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://css-tricks.com/gulp-for-beginners/
                </a>
                </p>
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
