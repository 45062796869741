import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class useSingleTheme extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-use-single-theme" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                    If you like to use a certain theme, you need to add theme filein head and use "js/scripts.single.theme.js" file instead of "js/scripts.js" file. As a side note, you will not be able to provide dark/light switch with this approach.
                </p>

<p>Here is the setup for css and javascript files:</p>

                <pre>
                  {`<head>
...Icons and vendor css files
<link rel="stylesheet" href="css/main.css" />
<link rel="stylesheet" href="css/dore.light.purple.min.css" /> //Or other theme of your choice
</head>
<body>
...Body content
...Vendor scripts
<script src="js/dore.script.js"></script>
<script src="js/scripts.single.theme.js"></script>
</body>`}
                </pre>

              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
