import React, { Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Jumbotron,
  CardSubtitle
} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class introduction extends Component {
  render() {
    return (

      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.introduction" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card className="mb-4">
              <CardBody>
                <Jumbotron>
                  <h1 className="display-4">Welcome!</h1>
                  <p className="lead">
                    Thank you for your interest in Dore jQuery Admin Template.
                    </p>
                  <hr className="my-4" />

                  <p className="mb-5">
                    <b>Item Name:</b> Dore jQuery Admin Template
                      <br />
                    <b>Author:</b> ColoredStrategies
                      <br />
                    <b>Version:</b> 2.3.0
                      <br />
                  </p>

                  <p>
                    This documentation is to help you regarding each step of
                    customization and get you started. Please go through the
                    documentation carefully to understand how this template is
                    made and how to edit it properly. HTML, Sass and jQuery knowledge is required to customize this template.
                  </p>

                  <p>
                    If you require any assistance you may reach us from <a className="btn-link" rel="noopener noreferrer" target="_blank" href="https://themeforest.net/item/dore-jquery-bootstrap-4-admin-dashboard/22604108/comments">comments section of the theme</a> or write to us from <a className="btn-link" rel="noopener noreferrer" target="_blank" href="https://themeforest.net/user/coloredstrategies">contact form at our profile page</a>.
                  </p>

                  <br /><br />
                  <p className="lead mb-0">
                    <a
                      className="btn btn-primary mr-1"
                      color="primary"
                      size="lg"
                      href="https://dore-jquery.coloredstrategies.com"
                    >
                      View Demo
                      </a>
                    <a
                      className="btn btn-outline-primary"
                      color="primary"
                      size="lg"
                      href="https://themeforest.net/item/dore-jquery-bootstrap-4-admin-dashboard/22604108"
                    >
                      View Item
                  </a>
                  </p>
                </Jumbotron>
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
