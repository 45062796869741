import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../helpers/IntlMessages";

export default class guideList extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.guide-list" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-change-directory-structure"}>
                    <IntlMessages id="menu.how-to-change-directory-structure" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>


            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-add-new-color-to-use-it-from-javascript"}>
                    <IntlMessages id="menu.how-to-add-new-color-to-use-it-from-javascript" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-use-single-theme"}>
                    <IntlMessages id="menu.how-to-use-single-theme" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-remove-side-theme-switcher"}>
                    <IntlMessages id="menu.how-to-remove-side-theme-switcher" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-change-starting-theme"}>
                    <IntlMessages id="menu.how-to-change-starting-theme" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-hide-sub-menu-and-only-have-main-menu"} >
                    <IntlMessages id="menu.how-to-hide-sub-menu-and-only-have-main-menu" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-use-rtl-mode-by-default"} >
                    <IntlMessages id="menu.how-to-use-rtl-mode-by-default" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-add-columns-to-existing-datatables"} >
                    <IntlMessages id="menu.how-to-add-columns-to-existing-datatables" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

            <Card className="mb-4">
              <CardBody>
                <p className="mb-0">
                  <NavLink
                    className="btn-link"
                    to={"guides/how-to-use-listing-pagination-and-search-datatables"}>
                    <IntlMessages id="menu.how-to-use-listing-pagination-and-search-datatables" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
