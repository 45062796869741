import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { NavLink } from "react-router-dom";


export default class files extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.files" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" lg="8" className="mb-4">
            <Card className="mb-4">
              <CardBody>
                <p>
                  Dore is designed to make learning curve as short as possible while providing flexibility and customizabi. We tried to provide clean and understandable code.
                </p>
                <p>
                  This part of the documentation will answer your questions about files of the project and provide some insights about them.
                </p>
                <br />
                <CardSubtitle><b>src/js/dore.script.js</b></CardSubtitle>
                <p>
                  Default entry point of the application and responsible for initializing external plugins.
                </p>

                <p>
                  Contains a jQuery plugin with a name <code>dore</code> which is responsible for making parts of the project like menu and plugins work. It also contains small utils and plugins like shift select and state button.
                </p>

                <p>
                  The file is separated by content and it's commented.
               </p>

                <br />
                <CardSubtitle><b>src/js/scripts.js</b></CardSubtitle>
                <p>
                  Initializes theme switcher and loads theme css files asynchronously. Also responsible for reading/writing theme, border radius and direction values from/to local storage. 
                </p>

                <br />
                <CardSubtitle><b>src/js/scripts.single.theme.js</b></CardSubtitle>
                <p>
                  This file can be used to have a single theme where theme css file needs to be added to html and loaded synchronously.
                </p>



                <br />
                <CardSubtitle><b>src/css/sass/_dore.style.scss</b></CardSubtitle>
                <p>
                  Main Sass file of the template. Contains all the parts necessary for theme styling but reads theme color specific values from other files.
                </p>

                <br />
                <CardSubtitle><b>src/css/sass/themes</b></CardSubtitle>
                <p>
                  This folder contains all theme specific files and files contains color and path definitions.
                </p>

              </CardBody>
            </Card>
          </Colxx>

          <Colxx xxs="12" lg="4">
            <Card>
              <CardBody>
                <CardTitle>
                  Related Guides
                </CardTitle>
                <p>
                  <NavLink
                      className="btn-link"
                      to={"/docs/guides/how-to-change-directory-structure"}
                    >
                    <IntlMessages id="menu.how-to-change-directory-structure" />
                    </NavLink>
                </p>
              
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
