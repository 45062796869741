import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import files from "./files";
import theming from "./theming";
import menu from "./menu";
import styling from "./styling";
import libraries from "./libraries";

const CodeBase = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/files`} />
      <Route path={`${match.url}/files`} component={files} />
      <Route path={`${match.url}/theming`} component={theming} />
      <Route path={`${match.url}/menu`} component={menu} />
      <Route path={`${match.url}/styling`} component={styling} />
      <Route path={`${match.url}/libraries`} component={libraries} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default CodeBase;
