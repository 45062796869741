import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class addColumnsDatatables extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-add-columns-to-existing-datatables" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                  Some of the datatables implementations contains column names defined via javascript. You will need to adjust <code>columns</code> properties of these Datatables for your own needs to be able to add or remove columns.
                </p>


                <pre className="mb-0">
                  {
                    `var $dataTableRows = $("#datatableRows").DataTable({
  bLengthChange: false,
  destroy: true,
  info: false,
  sDom: '<"row view-filter"<"col-sm-12"<"float-left"l><"float-right"f><"clearfix">>>t<"row view-pager"<"col-sm-12"<"text-center"ip>>>',
  pageLength: 10,
  columns: [
    { data: "Name" },
    { data: "Sales" },
    { data: "Stock" },
    { data: "Category" },
    { data: "Check" },
    { data: "Your New Column" }
  ],
...
                  `}
                </pre>

              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
