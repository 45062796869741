import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import addColumnsDatatables from "./add-columns-datatables";
import addNewColor from "./add-new-color";
import changeDirectory from "./change-directory";
import changeStartingTheme from "./change-starting-theme";
import hideSubmenu from "./hide-submenu";
import removeSideSwitch from "./remove-side-switch";
import useSingleTheme from "./use-single-theme";
import rtlDefault from "./rtl-default";
import listingDatatables from "./listing-datatables";




const GuidesItems = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`/docs/guide-list`} />
            <Route path={`${match.url}/how-to-change-directory-structure`} component={changeDirectory} />
            <Route path={`${match.url}/how-to-add-new-color-to-use-it-from-javascript`} component={addNewColor} />
            <Route path={`${match.url}/how-to-use-single-theme`} component={useSingleTheme} />
            <Route path={`${match.url}/how-to-remove-side-theme-switcher`} component={removeSideSwitch} />
            <Route path={`${match.url}/how-to-add-columns-to-existing-datatables`} component={addColumnsDatatables} />
            <Route path={`${match.url}/how-to-change-starting-theme`} component={changeStartingTheme} />
            <Route path={`${match.url}/how-to-hide-sub-menu-and-only-have-main-menu`} component={hideSubmenu} />
            <Route path={`${match.url}/how-to-use-rtl-mode-by-default`} component={rtlDefault} />
            <Route path={`${match.url}/how-to-use-listing-pagination-and-search-datatables`} component={listingDatatables} />
            
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default GuidesItems;

