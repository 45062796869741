import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class addNewColor extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-add-new-color-to-use-it-from-javascript" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                  There are some color variables that defined in css part and used in both css and javascript. This approach makes colors usage easy and consistent. You may find how predefined colors are received by javascript at <code>03.01. Getting Colors from CSS</code> part of the "dore.script.js" file.

                </p>

                <p>To define additional colors, you will need to be able to compile sass. You may find the steps below.</p>

                <ol className="mt-2">
                  <li>
                    Define color in theme scss file. For example create a
                    variable in dore.light.purple.scss file as
	<pre>{`$theme-color-7: #ff00ff;`}</pre>
                  </li>

                  <li>
                    At the "01.Base" section of _dore.style.scss write the
                    variable to ":root" like this and compile Sass.
	<pre>{`--theme-color-7: #{$theme - color - 7};`}</pre>
                  </li>

                  <li>
                    After this step new color is ready to be used in
                    javascript. On document ready you may get the color from
                    css with following code:
	<pre>
                      {` rootStyle.getPropertyValue("--theme-color-7").trim();`}
                    </pre>
                  </li>
                </ol>




              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
