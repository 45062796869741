const data = [
  {
    id: "gettingstarted",
    icon: "iconsminds-air-balloon-1",
    label: "menu.gettingstarted",
    to: "/docs/gettingstarted",
    subs: [
      {
        icon: "simple-icon-control-play",
        label: "menu.introduction",
        to: "/docs/gettingstarted/introduction"
      },
      {
        icon: "simple-icon-chemistry",
        label: "menu.development",
        to: "/docs/gettingstarted/development"
      } ,
      {
        icon: "simple-icon-folder",
        label: "menu.structure",
        to: "/docs/gettingstarted/structure"
      } ,
      {
        icon: "simple-icon-shuffle",
        label: "menu.changelog",
        to: "/docs/gettingstarted/changelog"
      } 
    ]
  },
  {
    id: "codebase",
    icon: "iconsminds-add-space-before-paragraph",
    label: "menu.codebase",
    to: "/docs/codebase",
    subs: [
      {
        icon: "simple-icon-doc",
        label: "menu.files",
        to: "/docs/codebase/files"
      },{
        icon: "simple-icon-magic-wand",
        label: "menu.styling",
        to: "/docs/codebase/styling"
      },  {
        icon: "simple-icon-energy",
        label: "menu.theming",
        to: "/docs/codebase/theming"
      }, {
        icon: "simple-icon-drawer",
        label: "menu.menu",
        to: "/docs/codebase/menu"
      },{
        icon: "simple-icon-puzzle",
        label: "menu.libraries",
        to: "/docs/codebase/libraries"
      },
    ]
  },
  {
    id: "guide-list",
    icon: "iconsminds-blackboard",
    label: "menu.guide-list",
    to: "/docs/guide-list"
  },
  {
    id: "buy",
    icon: "iconsminds-add-basket",
    label: "menu.buy",
    to: "https://1.envato.market/5kAb",
    newWindow: true
  }
];
export default data;
