import React, { Component, Fragment } from "react";
import { Row, CardBody, Card } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class structure extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.structure" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
              <p>
                <pre>{
`Dore-jQuery
├── .gitignore
├── .prettierrc
├── gulpfile.js
├── package.json
├── package-lock.json
└── src
    ├── css
    ├── font
    ├── img
    ├── js
    ├── Apps.Chat.html
    ├── Apps.MediaLibrary.html
    ├── Apps.MediaLibrary.ViewAudio.html
    ├── Apps.MediaLibrary.ViewFolder.html
    ├── Apps.MediaLibrary.ViewImage.html
    ├── Apps.MediaLibrary.ViewVideo.html
    ├── Apps.Survey.html
    ├── Apps.Survey.List.html
    ├── Apps.Todo.Details.html
    ├── Apps.Todo.List.html
    ├── Blank.Page.html
    ├── Dashboard.Analytics.html
    ├── Dashboard.Content.html
    ├── Dashboard.Default.html
    ├── Dashboard.Ecommerce.html
    ├── favicon.ico
    ├── index.html
    ├── Menu.Default.html
    ├── Menu.Hidden.html
    ├── Menu.Mainhidden.html
    ├── Menu.Subhidden.html
    ├── Pages.Auth.ForgotPassword.html
    ├── Pages.Auth.Login.html
    ├── Pages.Auth.Register.html
    ├── Pages.Blog.Detail.Alt.html
    ├── Pages.Blog.Detail.html
    ├── Pages.Blog.html
    ├── Pages.Misc.Coming.Soon.html
    ├── Pages.Misc.Error.html
    ├── Pages.Misc.Faq.html
    ├── Pages.Misc.Invoice.html
    ├── Pages.Misc.Invoice.Standalone.html
    ├── Pages.Misc.Knowledge.Base.html
    ├── Pages.Misc.Mailing.html
    ├── Pages.Misc.Mailing.Standalone.html
    ├── Pages.Misc.Maintenance.html
    ├── Pages.Misc.Pricing.html
    ├── Pages.Misc.Search.html
    ├── Pages.Product.Detail.html
    ├── Pages.Product.Images.html
    ├── Pages.Product.List.html
    ├── Pages.Product.Thumbs.html
    ├── Pages.Profile.Portfolio.html
    ├── Pages.Profile.Social.html
    ├── Ui.Components.Alerts.html
    ├── Ui.Components.Badges.html
    ├── Ui.Components.Buttons.html
    ├── Ui.Components.Cards.html
    ├── Ui.Components.Carousel.html
    ├── Ui.Components.Charts.html
    ├── Ui.Components.Collapse.html
    ├── Ui.Components.Dropdowns.html
    ├── Ui.Components.Editors.html
    ├── Ui.Components.Icons.html
    ├── Ui.Components.InputGroups.html
    ├── Ui.Components.Jumbotron.html
    ├── Ui.Components.Modal.html
    ├── Ui.Components.Navigation.html
    ├── Ui.Components.PopoverandTooltip.html
    ├── Ui.Components.Sortable.html
    ├── Ui.Components.Tables.html
    ├── Ui.Datatables.Default.html
    ├── Ui.Datatables.Pagination.html
    ├── Ui.Datatables.Rows.html
    ├── Ui.Datatables.Scroll.html
    ├── Ui.Forms.Components.html
    ├── Ui.Forms.Layouts.html
    ├── Ui.Forms.Validation.html
    └── Ui.Forms.Wizard.html`
}
                </pre>
                </p>
              </CardBody>
            </Card>



          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
