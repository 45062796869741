import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class removeSideSwitch extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-remove-side-theme-switcher" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                  Theme switcher is defined and added in "js/scripts.js" file. Variable name for the switcher is <code>themeColorsDom</code> and to remove it from pages, you will need to delete below line.
                </p>


                <pre className="mb-0">
                  {
                    `$("body").append(themeColorsDom);`}
                </pre>

              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
