import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { NavLink } from "react-router-dom";

export default class styling extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.styling" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" lg="8" className="mb-4">
            <Card>
              <CardBody>
                <CardSubtitle><b>Style Files</b></CardSubtitle>
                <p>
                  The template uses <b>Sass</b> for creating stylesheets.
                </p>
                <p>
                  Main style file is <code>css/sass/_dore.style.scss</code>.
                  There is one helper file which is <code>css/sass/_mixins.scss</code>.
              </p>

                <p>
                  There are also 10 scss files located under css/sass/themes
                  folder. This files creates theme specific variables and
                imports <code>_dore.style.scss</code> and <code>_mixins.scss</code> files to create final version as a
                          css file.
              </p>
                <p>
                  <code>_dore.style.scss</code> file is a single divided into
                  several logical parts. It is well commented.
              </p>


                <br />
                <CardSubtitle><b>Xxs & Xxl Classes</b></CardSubtitle>
                <p>
                  The template uses two additional Bootstrap column classes to provide a better responsive experience. <code>xxs</code> class is for under 420px and <code>xxl</code> class is for over 1440px. Here is the list of responsive breakpoints and their pixel values.
                  </p>

                <pre>
                  {
                    `xxs : -419
xs   : 420-575
sm   : 576-767
md   : 768-991
lg   : 992-1119
xl   : 1200-1439
xxl  : 1440+`}
                </pre>




                <br />
                <CardSubtitle><b>Body Classes</b></CardSubtitle>
                <p>
                  There are some classes defined to make style customization. These classes might be added to body according to your needs.
                </p>

                <p>
                  <b>right-menu</b><br />
                  This class is used to create an additional right side menu. You may see it in use at survey and chat applications.
                </p>

                <p>
                  <b>right-menu-always-hidden</b><br />
                  You may use this class instead of right-menu to make right menu always hidden. You will also need to update <code>app-menu-button</code> and remove <code>d-xl-none</code> class to make menu handle visible.
                </p>

                <p>
                  <b>show-spinner</b><br />
                  Shows a spinner until the document is ready and all the css and js is loaded.
                </p>

                <p>
                <b>no-footer</b><br />
                  Used for pages which does not need a footer like chat application.
                </p>


              </CardBody>
            </Card>
          </Colxx>

          <Colxx xxs="12" lg="4">
            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  Related Guides
                    </CardTitle>
                <p>
                  <NavLink
                    className="btn-link"
                    to={"/docs/guides/how-to-add-new-color-to-use-it-from-javascript"}>
                    <IntlMessages id="menu.how-to-add-new-color-to-use-it-from-javascript" />
                  </NavLink>
                </p>
              </CardBody>
            </Card>


            <Card className="mb-4">
              <CardBody>
                <CardTitle>
                  Related External Documentations
                </CardTitle>
                <p>
                  <a className="btn-link" target="_blank" rel="noopener noreferrer" href="https://sass-lang.com/">Sass</a>
                </p>
              </CardBody>
            </Card>

          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
