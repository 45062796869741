import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AppLayout from "../../layout/AppLayout";
import gettingStarted from "./gettingstarted";
import codebase from "./codebase";
import guides from "./guides";
import guideList from "./guide-list.js";

class App extends Component {
  render() {
    const { match } = this.props;

    return (
      <AppLayout>
        <Switch>
          <Redirect exact from={`${match.url}/`} to={`${match.url}/gettingstarted`} />
          <Route path={`${match.url}/gettingstarted`} component={gettingStarted} />
          <Route path={`${match.url}/codebase`} component={codebase} />
          <Route path={`${match.url}/guide-list`} component={guideList} />
          <Route path={`${match.url}/guides`} component={guides} />
          <Redirect to="/error" />
        </Switch>
      </AppLayout>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(App)
);
