import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";

export default class menu extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.menu" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" lg="8" className="mb-4">
            <Card>
              <CardBody>
              <p> We created a very useful and functional menu for this theme. It has 2 divisions. One for main items and another one for sub items.</p> 

              <p>It has 3 different use cases which you may examine under Menu section of the theme. This cases are "menu-default", "menu-sub-hidden" and "menu-hidden". To activate one of this cases, simply add it as a class to body. </p>
              
              <pre>
              {`<body id="app-container" class="menu-default">
<body id="app-container" class="menu-sub-hidden">
<body id="app-container" class="menu-hidden">
`}
</pre>

<p>We recommend menu-sub-hidden for app pages and menu-default for all other pages. Menu-default mutates to menu-sub-hidden under 1440px to give a little extra space to the user. 
</p> 
<p>Under 768px, all cases mutates to mobile-menu, which never pinned to screen.</p> 
<p>Final note about the menu is users don't have the ability to change cases, the case is defined by the developer.</p> 

              </CardBody>
            </Card>
          </Colxx>

          <Colxx xxs="12" lg="4">
          <Card>
            <CardBody>
              <CardTitle>
                Related Guides
              </CardTitle>
              
              <p>
                <NavLink
                  className="btn-link"
                  to={"/docs/guides/how-to-hide-sub-menu-and-only-have-main-menu"}>
                  <IntlMessages id="menu.how-to-hide-sub-menu-and-only-have-main-menu" />
                </NavLink>
              </p>

            </CardBody>
          </Card>
        </Colxx>

        </Row>
      </Fragment>
    );
  }
}
