import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class listingDatatables extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-use-listing-pagination-and-search-datatables" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>
                <p>
                  We have added some pages to provide a ui for listing. These pages are Pages.Product.Images.html, Pages.Product.List.html and Pages.Product.Thumbs.html and they are designed to be rendered by a backend. There are no functionality at them so you will need to provide pagination, search and so on via your backend.
                </p>

                <p className="mb-0">
                  If you would like to provide only the data and not the functionality, then you may use Ui.Datatables.Rows.html file. This file implements Datatables and provides pagination and search via frontend. You may find implementation in "js/dore.script.js" commented with <code>Datatable with rows</code> part.
                </p>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
