import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../../helpers/IntlMessages";

export default class theming extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.theming" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" lg="8" className="mb-4">
            <Card>
              <CardBody>
                <CardSubtitle><b>Theme Switching</b></CardSubtitle>
                <p>
                  Theme color switch is done by <code>js/scripts.js</code> file.
                  The file loads corresponding css file from css directory by
                  user choice. Saves current theme to local storage.
                </p>

                <br />
                <CardSubtitle><b>Providing Theme Switcher</b></CardSubtitle>

                <p>If you like to give users an ability to change theme color like the one at the preview of the template, theme files should not be included in head and should be loaded over <code>js/scripts.js</code> file.</p>

                <pre>
                  {
                    `<head>
  ...Icons and vendor css files
  <link rel="stylesheet" href="css/main.css" />
</head>
<body>
  ...Body content ...Vendor scripts
  <script src="js/dore.script.js" />
  <script src="js/scripts.js" />
</body>`
                  }
                </pre>


                <br />
                <CardSubtitle><b>Providing Single Theme (Recommended)</b></CardSubtitle>

                <p>
                  If you like to use a certain theme, you need to add theme file
                  in head and use js/scripts.single.theme.js file instead of
                  js/scripts.js file.
                </p>

                <p>Here is the setup for css and javascript files:</p>

                <pre>
                  {`<head>
...Icons and vendor css files
<link rel="stylesheet" href="css/main.css" />
<link rel="stylesheet" href="css/dore.light.purple.min.css" /> //Or other theme of your choice
</head>
<body>
...Body content
...Vendor scripts
<script src="js/dore.script.js"></script>
<script src="js/scripts.single.theme.js"></script>
</body>`}
                </pre>

                <br />
                <CardSubtitle><b>Theme Colors</b></CardSubtitle>

                <p>
                  There are some predefined colors in every scss theme file. For
                  consistency there is no color definition in javascript files.
                  Css files write color values to ":root" and javascript reads
                  from there. In dore.script.js file you may examine the part
                  Getting Colors from CSS.
                </p>

                <p>
                  To define additional colors, these are the steps to follow.
                <ol className="mt-2">
                    <li>
                      Define color in theme scss file. For example create a
                      variable in dore.light.purple.scss file as
                    <pre>{`$theme-color-7: #ff00ff;`}</pre>
                    </li>

                    <li>
                      At the "01.Base" section of _dore.style.scss write the
                      variable to ":root" like this and compile Sass.
                    <pre>{`--theme-color-7: #{$theme - color - 7};`}</pre>
                    </li>

                    <li>
                      After this step new color is ready to be used in
                      javascript. On document ready you may get the color from
                      css with following code:
                    <pre>
                        {` rootStyle.getPropertyValue("--theme-color-7").trim();`}
                      </pre>
                    </li>
                  </ol>
                </p>

              </CardBody>
            </Card>
          </Colxx>

          <Colxx xxs="12" lg="4">
            <Card>
              <CardBody>
                <CardTitle>
                  Related Guides
                </CardTitle>
                
                <p>
                  <NavLink
                    className="btn-link"
                    to={"/docs/guides/how-to-use-single-theme"}>
                    <IntlMessages id="menu.how-to-use-single-theme" />
                  </NavLink>
                </p>
                
                <p>
                  <NavLink
                    className="btn-link"
                    to={"/docs/guides/how-to-remove-side-theme-switcher"}>
                    <IntlMessages id="menu.how-to-remove-side-theme-switcher" />
                  </NavLink>
                </p>

                <p>
                  <NavLink
                    className="btn-link"
                    to={"/docs/guides/how-to-change-starting-theme"}>
                    <IntlMessages id="menu.how-to-change-starting-theme" />
                  </NavLink>
                </p>

                <p>
                  <NavLink
                    className="btn-link"
                    to={"/docs/guides/how-to-add-new-color-to-use-it-from-javascript"}>
                    <IntlMessages id="menu.how-to-add-new-color-to-use-it-from-javascript" />
                  </NavLink>
                </p>

              </CardBody>
            </Card>
          </Colxx>

        </Row>
      </Fragment>
    )
  }
}
