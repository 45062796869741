import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import introduction from "./introduction";
import development from "./development";
import structure from "./structure";
import changelog from "./changelog";

const GettingStarted = ({ match }) => (
  <div className="dashboard-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/introduction`} />
      <Route path={`${match.url}/introduction`} component={introduction} />
      <Route path={`${match.url}/development`} component={development} />
      <Route path={`${match.url}/structure`} component={structure} />
      <Route path={`${match.url}/changelog`} component={changelog} />
      <Redirect to="/error" />
    </Switch>
  </div>
);
export default GettingStarted;
