module.exports = {
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  "menu.docs": "Home",
  "menu.gettingstarted": "Getting Started",
  "menu.introduction": "Introduction",
  "menu.development": "Development",
  "menu.production": "Production",
  "menu.deployment": "Deployment",
  "menu.structure": "Structure",
  "menu.changelog": "Changelog",
  "menu.codebase": "Codebase",
  "menu.files": "Files",
  "menu.theming": "Theming",
  "menu.menu": "Menu",
  "menu.layout": "Layout",
  "menu.language": "Language",
  "menu.styling": "Styling",
  "menu.libraries": "Libraries",
  "menu.guides": "Guides",
  "menu.guide-list": "Guides",
  "menu.buy": "Buy Now",

  "menu.how-to-change-directory-structure": "How to change directory structure?",
  "menu.how-to-add-new-color-to-use-it-from-javascript": "How to add a new color to use it from javascript?",
  "menu.how-to-use-single-theme": "How to use a single theme?",
  "menu.how-to-remove-side-theme-switcher": "How to remove side theme switcher?",
  "menu.how-to-add-columns-to-existing-datatables": "How to add columns to existing Datatables?",
  "menu.how-to-change-starting-theme": "How to change starting theme?",
  "menu.how-to-hide-sub-menu-and-only-have-main-menu": "How to hide sub menu and only have a main menu?",
  "menu.how-to-use-rtl-mode-by-default": "How to use rtl by default?",
  "menu.how-to-use-listing-pagination-and-search-datatables": "How to use listing page pagination and search via Datatables?",
  

  
  
  
  
 /* 04.Error Page */
 "pages.error-title": "Ooops... looks like an error occurred!",
 "pages.error-code": "Error code",
 "pages.go-back-home": "GO BACK HOME",
};
