import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

export default class rtlDefault extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.how-to-use-rtl-mode-by-default" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">

            <Card className="mb-4">
              <CardBody>

                <p>You will need to change below variable at "js/scripts.single.theme.js" or "js/scripts.js" file to rtl.</p>
                <pre>
                  {
                    `var direction = "ltr";`
                  }
                </pre>
                <p className="mb-0">
                  Alternatively, you may add <code>rtl</code> class to body tag of the file and <code>dir="rtl"</code> to html tag.
                </p>

              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </Fragment>
    )
  }
}
